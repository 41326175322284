<template>
  <div>
    <div class="text-center mb-5 mb-sm-0">
      <inline-svg src="/media/svg/icons/General/Error.svg" height="70" width="70"/>
    </div>
    <perfect-scrollbar class="scroll-wrapper scroll pr-3">
      <div class="text-center">
        <div class="fs-xl font-weight-bolder">{{ $t('label.link_expired') }}</div>
        <div>{{ $t('description.booking_changes_request_not_available') }}</div>
        <div class="d-inline-block booking-id-wrapper mt-5 mb-10 px-3 py-2">
          {{ $t('label.booking_id') }}: <span class="text-green">#{{ bookingId }}</span>
        </div>
      </div>
      <div class="booking-changes-wrapper py-4 m-0">
        <div class="booking-changes d-flex flex-column justify-content-between px-10 mb-sm-0">
          <div class="d-flex align-items-center" style="gap: 20px">
            {{ $t('description.booking_changes_request_not_available_desc') }}
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { dateMixin } from '@/mixins/dateMixin';

export default {
  mixins: [dateMixin],
  props: {
    bookingId: String,
    booking: Array
  }
}
</script>

<style lang="scss" scoped>
.scroll-wrapper {
  height: auto;
}
</style>