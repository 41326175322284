<template>
  <div>
    <div class="text-center mb-5 mb-sm-0">
      <inline-svg src="/media/svg/icons/General/Success.svg" height="70" width="70"/>
    </div>
    <perfect-scrollbar class="scroll-wrapper scroll pr-3">
      <div class="text-center">
        <div class="fs-xl font-weight-bolder">{{ $t('label.changes_confirmed') }}!</div>
        <div>{{ $t('label.booking_updated') }}</div>
        <div class="d-inline-block booking-id-wrapper my-5 px-3 py-2">
          {{ $t('label.booking_id') }}: <span class="text-green">#{{ bookingId }}</span>
        </div>
      </div>
      <div class="booking-changes-wrapper pt-4 m-0">
        <div class="booking-changes d-flex flex-column justify-content-between px-10 mb-5 mb-sm-0">
          <div class="fs-md font-weight-bolder mb-3">{{ $t('label.updated_booking_details') }}</div>
          <booking-detail v-for="item in booking" :item="item" type="changed" success :key="item.key" style="gap: 20px"/>
        </div>
        <div class="info-message text-center bg-light-green p-2 mt-3">
          {{ $t('description.notification_with_confirm_booking_changes_sent') }}
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { dateMixin } from '@/mixins/dateMixin';
import BookingDetail from "./BookingDetail.vue";

export default {
  components: { BookingDetail },
  mixins: [dateMixin],
  props: {
    bookingId: String,
    booking: Array
  }
}
</script>

<style lang="scss" scoped>
.scroll-wrapper {
  height: calc(100vh - 375px);
}
</style>