<template>
  <b-row class="justify-content-center m-0">
    <b-col md="6" xl="5">
      <b-card v-if="bookingComponent" class="card-custom position-relative user-select-none my-10">
        <template #default>
          <component
            :is="bookingComponent"
            :booking="computedBookingChanges"
            :booking-id="bookingId"
            :token="token"
            @bookingChanged="bookingComponent = $event ? 'BookingChangesConfirmed' : 'BookingChangesDeclined'"
          />
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import { dateMixin } from '@/mixins/dateMixin';
import BookingChangesConfirm from "./components/BookingChangesConfirm.vue";
import BookingChangesConfirmed from "./components/BookingChangesConfirmed.vue";
import BookingChangesDeclined from "./components/BookingChangesDeclined.vue";
import BookingChangesLinkExpired from "./components/BookingChangesLinkExpired.vue";

export default {
  components: {
    BookingChangesConfirm,
    BookingChangesConfirmed,
    BookingChangesDeclined,
    BookingChangesLinkExpired
  },
  mixins: [dateMixin],
  data: () => ({
    bookingComponent: null
  }),
  mounted() {
    this.getBookingChanges();
  },
  computed: {
    ...mapGetters({
      bookingChanges: 'guestBookingStore/BOOKING_CHANGES'
    }),
    bookingId() {
      return this.$route.query?.booking_id;
    },
    computedBookingChanges() {
      if (!this.bookingChanges) return [];

      const fields = [
        { key: 'start_date', icon: 'fa-calendar', placeholder: 'placeholder.check_in_date', isDate: true },
        { key: 'end_date', icon: 'fa-calendar', placeholder: 'placeholder.check_out_date', isDate: true },
        { key: 'adults', icon: 'fa-user', placeholder: 'label.attendees', isDate: false },
        { key: 'children', icon: 'fa-child', placeholder: 'label.attendees', isDate: false }
      ];

      return fields
        .map(({ key, icon, placeholder, isDate }) => {
          const initialKey = `initial_${key}`;
          const changedKey = `changed_${key}`;

          if (!this.bookingChanges.hasOwnProperty(initialKey) || !this.bookingChanges.hasOwnProperty(changedKey)) {
            return null;
          }

          let initialValue = this.bookingChanges[initialKey];
          let changedValue = this.bookingChanges[changedKey];

          if (isDate) {
            const extractDate = (value) => {
              if (!value) return null;
              return value.split(' ')[0];
            };
            initialValue = extractDate(initialValue);
            changedValue = extractDate(changedValue);
          }

          if (initialValue !== changedValue) {
            return {
              key,
              icon,
              placeholder,
              initial: isDate ? this.getLocaledDate(this.bookingChanges[initialKey]) : this.$tc(`label.${key}_plural`, initialValue),
              changed: isDate ? this.getLocaledDate(this.bookingChanges[changedKey]) : this.$tc(`label.${key}_plural`, changedValue)
            };
          }
          return null;
        })
        .filter(Boolean);
    },
    token() {
      return this.$route.query?.token;
    },
    language() {
      return this.$route.query?.lang;
    }
  },
  watch: {
    bookingChanges: {
      handler(data) {
        if (data && Object.keys(data).length) {
          this.bookingComponent = 'BookingChangesConfirm';
        }
      },
      deep: true
    },
    language: {
      immediate: true,
      handler(value) {
        if (!value) return;
        if (value === 'ka') value = 'ge';
        this.$emit('change-language', value);
      }
    }
  },
  methods: {
    async getBookingChanges() {
      if (!this.bookingId || !this.token) return;
      try {
        await this.$store.dispatch('guestBookingStore/GET_BOOKING_CHANGES', {
          bookingId: this.bookingId,
          token: this.token
        });
      } catch(error) {
        this.bookingComponent = 'BookingChangesLinkExpired';
      }
    }
  }
}
</script>

<style lang="scss">
.card-custom {
  border-radius: 20px;

  .scroll-wrapper {
    overflow: auto;
  }

  .info-message {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .booking-id-wrapper {
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 #00000040;
  }

  .booking-changes-wrapper {
    border: 1px solid #666666;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 #00000040;
  }

  @media (max-width: 768px) {
    .scroll-wrapper {
      height: auto;
    }
  }
}
</style>