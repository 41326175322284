<template>
  <div class="d-flex align-items-center mb-2" style="gap: 20px" :key="item.key">
    <i class="menu-icon far " :class="[item.icon, {'text-light-green': success}]"/>
    <div>
      <div>{{ item[type] }}</div>
      <div>{{ $t(item.placeholder) }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    success: Boolean,
    type: String
  }
}
</script>