<template>
  <div>
    <div class="text-left text-md-center fs-xxxl font-weight-bolder">ORBI CITY</div>
    <perfect-scrollbar class="scroll-wrapper scroll pr-3">
      <div class="text-left fs-xl font-weight-bolder text-uppercase">{{ $t('title.booking_details') }}</div>
      <div>{{ $t('description.review_proposed_booking_changes') }}</div>
      <div class="d-inline-block booking-id-wrapper my-5 px-3 py-2">
        {{ $t('label.booking_id') }}: <span class="text-green">#{{ bookingId }}</span>
      </div>
      <div class="booking-changes-wrapper row py-4 m-0">
        <div class="col-sm-6 booking-changes d-flex flex-column justify-content-between border-right px-10 mb-5 mb-sm-0">
          <div class="fs-md font-weight-bolder mb-3">{{ $t('label.current_booking') }}</div>
          <booking-detail v-for="item in booking" :item="item" type="initial" :key="item.key" style="gap: 20px"/>
        </div>
        <div class="col-sm-6 booking-changes d-flex flex-column justify-content-between px-10">
          <div class="fs-md font-weight-bolder mb-3">{{ $t('label.proposed_changes') }}</div>
          <booking-detail v-for="item in booking" :item="item" type="changed" success :key="item.key" style="gap: 20px"/>
        </div>
      </div>
    </perfect-scrollbar>

    <div class="text-right mt-5">
      <button class="btn font-weight-bold mr-3" @click="rejectChanges">
        {{ $t('btn.decline') }}
      </button>
      <button class="btn btn-green font-weight-bold" @click="acceptChanges">
        {{ $t('btn.confirm_changes') }}
      </button>
    </div>
  </div>
</template>

<script>
import { dateMixin } from '@/mixins/dateMixin';
import BookingDetail from "./BookingDetail.vue";

export default {
  mixins: [dateMixin],
  components: {
    BookingDetail
  },
  props: {
    token: String,
    bookingId: String,
    booking: Array
  },
  methods: {
    async acceptChanges() {
      try {
        await this.$store.dispatch('guestBookingStore/ACCEPT_BOOKING_CHANGES', {
          booking_id: this.bookingId,
          token: this.token
        });
        this.$emit('bookingChanged', true);
      } catch(error) {
        console.log('confirm changes error::', error);
      }
    },
    async rejectChanges() {
      try {
        await this.$store.dispatch('guestBookingStore/REJECT_BOOKING_CHANGES', {
          booking_id: this.bookingId,
          token: this.token
        });
        this.$emit('bookingChanged', false);
      } catch(error) {
        console.log('confirm changes error::', error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-wrapper {
  height: calc(100vh - 420px);
}
</style>